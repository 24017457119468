html, body {
  position: fixed;
  height: var(--vh-100);
  overflow: hidden;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .chakra-popover__content {
    margin: 10px;
  }
}
