
/* https://github.com/quill-mention/quill-mention/blob/master/src/quill.mention.css */

.ql-mention-list-container {
  width: 270px;
  transition: background-color 0.2s;
  box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px,
    rgba(15, 15, 15, 0.2) 0px 9px 24px;
  z-index: 9001;
  overflow: auto;
}

.ql-mention-list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.ql-mention-list-item {
  cursor: pointer;
  line-height: 44px;
  font-size: 16px;
  padding: 0 20px;
  vertical-align: middle;
}

.ql-mention-list-item.disabled {
  cursor: auto;
}

.ql-mention-list-item.selected {
  text-decoration: none;
}

.mention {
  height: 24px;
  width: 65px;
  border-radius: 4px;
  transition: background-color 0.2s;
  padding: 3px 0;
  margin-right: 2px;
  user-select: all;
}

.mention > span {
  margin: 0 3px;
}
